import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import './App.css';
import Home from './Home';
import AboutUs from './pages/AboutUs';
import Assistance from './pages/Assistance';
import Builder from './pages/Builder';
import Contacts from './pages/Contacts';
import Contractor from './pages/Contractor';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Navbar from './components/Navbar'
import Headroom from "react-headroom";


function App() {
    return (
        <div className="relative">
            <Router>
                <Headroom>
                    <Navbar/>
                </Headroom>
                <Routes>
                    <Route path="/" exact element={<Home/>}/>
                    <Route path="/about" element={<AboutUs/>}/>
                    <Route path="/assistance" element={<Assistance/>}/>
                    <Route path="/builder" element={<Builder/>}/>
                    <Route path="/contacts" element={<Contacts/>}/>
                    <Route path="/contractor" element={<Contractor/>}/>
                    <Route path="/projects" element={<Projects/>}/>
                    <Route path="/services" element={<Services/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
