import React from 'react'
import { Gallery } from "react-grid-gallery";
import {Helmet} from "react-helmet";

const images = [
    {
        src: 'https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg',
        thumbnail: 'image1_thumbnail.jpg',
        caption: 'Image 1'
    },
    {
        src: 'https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg',
        thumbnail: 'image2_thumbnail.jpg',
        caption: 'Image 2'
    },
    {
        src: 'https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg',
        thumbnail: 'image3_thumbnail.jpg',
        caption: 'Image 3'
    },
    // Add more images here
];

const AboutUs = () => {
    return (
        <div className="w-full relative">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>About Us | Enhance Projects Private Limited | A Legacy of Quality & Innovation</title>
                <meta name="description" content="Discover the story behind Enhance Projects Private Limited and how we
                have established ourselves as one of the leading construction and real estate development companies.
                With a rich history and a commitment to innovation, our team is dedicated to building better futures
                for our clients. Learn more about our values, mission, and history on our About Us page."/>
            </Helmet>
            <div className="w-full h-[80vh] bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(https://source.unsplash.com/1920x800/?company)`}}>
                <div className="w-full relative flex bg-dark bg-opacity-50 items-center justify-center h-full">
                    <h2 className="text-white text-center text-4xl lg:text-6xl font-primary font-bold tracking-wide">Enhance Projects Private Limited</h2>
                </div>
            </div>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 px-8 py-10 md:px-28 xl:px-40 gap-2 lg:gap-8">
                <div className="p-2 lg:p-4 border border-accent rounded">
                    <h3 className="text-xl lg:text-2xl font-bold text-primary text-center mb-2">Who we are?</h3>
                    <p className="text-dark text-sm text-center">Started in 1970's as M/S M.C Jain, We have taken many government civil contracts and delivered it with full satisfaction. We have been working for Government Organisations such as BSNL, CCL and CPWD.</p>
                </div>
                <div className="p-2 lg:p-4 border border-accent rounded">
                    <h3 className="text-xl lg:text-2xl font-bold text-primary text-center mb-2">Our Organization</h3>
                    <p className="text-dark text-sm text-center">Enhance projects has specialized experience in the Construction Industry for more than 30 years, We at Enhance Projects, have carved a niche for ourselves in the industry as one of the leading Civil Contractors and Real Estate Developer of the region.</p>
                </div>
                <div className="p-2 lg:p-4 border border-accent rounded">
                    <h3 className="text-xl lg:text-2xl font-bold text-primary text-center mb-2">Vision</h3>
                    <p className="text-dark text-sm text-center">To be the top provider of quality construction and engineering services in India.</p>
                </div>
                <div className="p-2 lg:p-4 border border-accent rounded">
                    <h3 className="text-xl lg:text-2xl font-bold text-primary text-center mb-2">Corporate Social Responsibility</h3>
                    <p className="text-dark text-sm text-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A adipisci consectetur cum dolorum exercitationem molestiae necessitatibus pariatur reprehenderit sequi, tempora! Asperiores cum enim pariatur perspiciatis quaerat rem sed ut voluptatibus!</p>
                </div>
            </div>
            <div className="w-full px-8 md:px-28 xl:px-40">
                <Gallery images={images} enableImageSelection={false} />
            </div>
        </div>
    )
}

export default AboutUs
