import React from 'react'
import {ReactComponent as CivilIcon} from '../assets/images/icons/civil_works.svg'
import {ReactComponent as InfraIcon} from '../assets/images/icons/infrastructure_development.svg'
import {ReactComponent as BuildingConstructionIcon} from '../assets/images/icons/building_construction.svg'
import {ReactComponent as ArchIcon} from '../assets/images/icons/architecture_design.svg'
import {ReactComponent as BuildingPlumbingIcon} from '../assets/images/icons/building_plumbing.svg'
import {ReactComponent as PropertyIcon} from '../assets/images/icons/property_development.svg'
import {ReactComponent as ProjectIcon} from '../assets/images/icons/project_management.svg'
import {ReactComponent as SafetyIcon} from '../assets/images/icons/safety_quality.svg'
import Map from "../components/Map";
import Footer from "../components/Footer";
import {Helmet} from "react-helmet";
const services_img = require("../assets/images/services-min.jpg")

const Services = () => {
    return (
        <div className="w-full relative" id="">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Our Services | Enhance Projects Private Limited</title>
                <meta name="description"
                      content="Enhance Projects Private Limited offers a comprehensive range of services to meet all
                      your construction and real estate development needs. From architecture design to building plumbing,
                      project management, and safety and quality, our team is equipped to handle every aspect of your project.
                      Discover more about our services and how we can help bring your vision to life."/>
            </Helmet>
            <div className="w-full h-[50vh] lg:h-[80vh] bg-cover bg-no-repeat bg-center"
                 style={{backgroundImage: `url(${services_img})`}}>
                <div className="w-full relative flex flex-col bg-dark bg-opacity-50 items-center justify-center h-full">
                    <h2 className="text-white text-center text-4xl lg:text-6xl font-primary font-bold tracking-wide">Our
                        Services</h2>
                </div>
            </div>
            <div className=" px-5 py-5 lg:py-10 md:px-28 lg:px-40 ">
                <div className="w-full border-b border-accent pb-10">
                    <p className="text-sm leading-relaxed lg:text-base xl:text-lg w-full text-justify">
                        Welcome to the services page of Enhance Projects Private Limited. We are a leading contractor
                        and
                        builder known for our expertise in delivering high-quality construction projects on time and
                        within
                        budget. We have over 30 years of experience in the industry and a team of highly skilled and
                        experienced professionals who specialize in a wide range of construction services.
                    </p>
                </div>
                <div className="w-full py-6 lg:py-10">
                    <p className="text-sm leading-relaxed lg:text-base xl:text-lg w-full text-center pb-6">
                        We offer a wide range of services to meet the diverse needs of our clients. Our services
                        include:
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 xl:gap-8">
                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <CivilIcon/>
                            </div>

                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Civil
                                Works</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">We specialize in a wide range of
                                civil works including road
                                construction, bridge construction, pavement, and dam construction. Our team of experts
                                ensures
                                that each project is executed with the highest standards of quality and safety.</p>
                        </div>


                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <InfraIcon/>
                            </div>

                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Infrastructure
                                Development</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">
                                We have extensive experience in the development of infrastructure
                                projects including airports, ports, and power plants. We work closely with our clients
                                to
                                understand their specific needs and deliver projects that are tailored to their unique
                                requirements.
                            </p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <BuildingConstructionIcon/>
                            </div>
                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Building
                                Construction</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">We specialize in the construction
                                of residential, commercial, and
                                industrial buildings. Our projects are characterized by their innovative designs,
                                superior
                                construction quality, and attention to detail.
                            </p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <ArchIcon/>
                            </div>
                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Architecture
                                Design</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">We offer professional architecture
                                design services to our clients. We
                                work closely with our clients to understand their specific needs and create designs that
                                are
                                tailored to their unique requirements.
                            </p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <BuildingPlumbingIcon/>
                            </div>
                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Building
                                Plumbing</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">Our team of plumbing experts have
                                experience in designing and
                                installing plumbing systems for new construction and remodeling projects. We ensure that
                                all of
                                our plumbing systems meet industry standards and regulations.
                            </p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <PropertyIcon/>
                            </div>
                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Property
                                Development</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">As a real estate developer, we are
                                committed to providing modern
                                residential and commercial opportunities to those who are looking for the best use of
                                their
                                hard-earned money. We strive to build world-class real estate concepts with the highest
                                standards of professionalism, ethics, quality, and customer service.
                            </p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <ProjectIcon/>
                            </div>
                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Project
                                Management</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">We have a team of experienced
                                project managers who will work closely
                                with you to ensure that your project is completed on time and within budget. Our project
                                management services include planning, scheduling, and cost control.
                            </p>
                        </div>
                        <div className="bg-white shadow-md rounded p-4 lg:p-5 xl:px-6">
                            <div className="w-full w-12 lg:w-14 mb-2 fill-accent">
                                <SafetyIcon/>
                            </div>
                            <h3 className="text-black text-lg lg:text-xl xl:text-2xl font-medium mb-1 font-bold font-primary">Safety
                                & Quality</h3>
                            <p className="text-dark text-xs lg:text-sm xl:text-base">We are committed to safety and
                                quality, and have implemented several initiatives to ensure that all of our projects are
                                executed in a safe and efficient manner. We also adhere to industry standards and
                                regulations to ensure that our projects are of the highest quality.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="w-full py-6 lg:py-10">
                    <h4 className="text-primary text-xl lg:text-2xl xl:text-3xl mb-2 lg:mb-6 font-bold font-primary text-center">Business
                        Opportunities</h4>
                    <p className="text-sm leading-relaxed lg:text-base xl:text-lg w-full text-justify pb-6 lg:pb-8 xl:pb-10">
                        Our organization is involved in construction work of different sectors, these are Power
                        projects, Corporate houses, pharmaceutical projects, Institutional and Residential projects,
                        Infrastructure projects etc. Enhance Projects success depends on their Wide base of
                        experienced and supportive vendors which includes Manufacturers, Traders, Suppliers. As well as
                        labour-contractors, Fabricators, Installer & Sub contractors of several fields. At Enhance
                        Projects, We invite vendors to do business in the following fields.
                    </p>
                    <div className="w-full">
                        <div className="bg-white shadow-md rounded w-full">
                            <ul className="columns-1 md:columns-2 lg:columns-3 md:gap-x-4 list-disc text-xs lg:text-sm xl:text-base p-4 lg:py-6 xl:py-8 lg:px-5 xl:px-12">
                                <li className="ml-4">Cement, Reinforcement & Structural Steel.</li>
                                <li className="ml-4">Bricks & concrete & stone Blocks</li>
                                <li className="ml-4">Pipes & Plumbing and Sanitary Items</li>
                                <li className="ml-4">Electrical Goods and equipment</li>
                                <li className="ml-4">Wood, Plywood, Laminates</li>
                                <li className="ml-4">Glass and Glass products</li>
                                <li className="ml-4">Elevators</li>
                                <li className="ml-4">Hardware fittings & fixtures</li>
                                <li className="ml-4">Fabrication and Erection</li>
                                <li className="ml-4">Civil Subcontracting</li>
                                <li className="ml-4">Construction machinery, equipment, Tools.</li>
                                <li className="ml-4">Bitumen and related products</li>
                                <li className="ml-4">Roofing material</li>
                                <li className="ml-4">Waterproofing,Construction Chemical</li>
                                <li className="ml-4">False ceiling, False flooring, Insulation</li>
                                <li className="ml-4">Paints and related products</li>
                                <li className="ml-4">Doors, Windows,Rolling Shutters</li>
                                <li className="ml-4">Flooring material e.g. Tiles, stones, granite, Kota, ceramic,
                                    glazed
                                </li>
                                <li className="ml-4">Electrical work</li>
                                <li className="ml-4">Fire-fighting work</li>
                                <li className="ml-4">CCTV Access control work</li>
                                <li className="ml-4">Expansion joint</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-96 w-full">
                <Map/>
            </div>
            <Footer/>
        </div>
    )
}

export default Services
