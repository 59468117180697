import React, {useState} from 'react';
import logo from '../assets/images/logo.png';
import {NavLink} from 'react-router-dom';


const Menulist = [
    {
        title: "About Us",
        url: "/about"
    },
    {
        title: "Projects",
        url: "/projects"
    },

    {
        title: "Assistance",
        url: "/assistance"
    },
    {
        title: "Services",
        url: "/services"
    },
]
const Navbar = () => {
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    }

    const menuList = Menulist.map(({url, title}, index) => {
        return (
            <li className="text-4xl lg:text-lg uppercase text-white lg:text-black hover:text-primary tracking-wide font-semibold lg:px-3 lg:tracking-normal"
                key={index}>
                <NavLink to={url} className="list border-b-0 hover:border-b-4 border-primary transition-all" onClick={handleClick}>
                    {title}
                </NavLink>
            </li>
        )
    })

    return (
        <nav
            className="navbar p-1 flex justify-between w-full items-center bg-white text-primary px-4 lg:px-12 xl:px-16 relative">
            <div className="z-20 h-12 lg:h-16 xl:h-20 flex items-center justify-center rounded-lg nav_logo transition-all">
                <NavLink to={"/"} className="h-full">
                    <img className="h-full" src={logo} alt=""/>
                </NavLink>
            </div>

            <div className="w-full flex items-center justify-end">
                <span className="hamburger lg:hidden z-40 flex items-center justify-center" onClick={handleClick}>
                    <span
                        className={"material-icons-round transition-all cursor-pointer " + (clicked ? "text-white text-4xl" : "text-primary")}>{clicked ? " close " : " menu "}</span>
                </span>
                <ul className={`lg:flex lg:items-center w-screen h-screen left-0 top-0 flex flex-col lg:flex-row items-center justify-evenly py-40 lg:py-0 lg:h-auto fixed lg:relative lg:z-auto z-30 text-center bg-secondary lg:bg-transparent w-full lg:w-auto transition-all duration-300 ease-in  ${clicked ? "translate-y-0  opacity-100" : "-translate-y-full lg:-translate-y-0 opacity-0 lg:opacity-100"}`}>
                    {menuList}
                </ul>
            </div>
        </nav>
    )
}

export default Navbar
