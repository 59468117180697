import React, {useRef, useEffect} from "react";
import {gsap} from "gsap";
import {isMobile} from 'react-device-detect';
const builder_bg = require('../assets/images/builder_bg.jpg')
const contractor_bg = require('../assets/images/contractor_bg.jpg')


function MainDivision() {
    const divisionOne = useRef()
    const divisionTwo = useRef()
    const middleDivider = useRef()

    useEffect(() => {
    });

    const mouseOver = (division) => {
        if(!isMobile){
            if (division === divisionOne){
                gsap.to(divisionOne.current, {duration: 1, width: '90vw', backgroundSize: "90vw"})
                gsap.to(divisionOne.current.childNodes[0].childNodes[1], {top: 0, opacity: 1, autoAlpha: 1, delay: 0.5})
                gsap.to(divisionTwo.current, {duration: 1, width: '10vw', backgroundSize: "30vw"})
                gsap.to(divisionTwo.current.childNodes[0].childNodes[0], {opacity: 0})
            }else{
                gsap.to(divisionTwo.current,  {duration: 1, width: '90vw', backgroundSize: "90vw"})
                gsap.to(divisionTwo.current.childNodes[0].childNodes[1], {top: 0, opacity: 1, autoAlpha: 1, delay: 0.5})
                gsap.to(divisionOne.current,  {duration: 1, width: '10vw', backgroundSize: "30vw"})
                gsap.to(divisionOne.current.childNodes[0].childNodes[0], {opacity: 0})
            }
        }
    }

    const mouseOut = () => {
        gsap.to([divisionTwo.current, divisionOne.current],  {duration: 1, width: '50vw', backgroundSize: "30vw"})
        gsap.to(divisionOne.current.childNodes[0].childNodes[1], {top: 30, opacity: 0, autoAlpha: 0})
        gsap.to(divisionTwo.current.childNodes[0].childNodes[1], {top: 30, opacity: 0, autoAlpha: 0})
        gsap.to([divisionTwo.current.childNodes[0].childNodes[0], divisionOne.current.childNodes[0].childNodes[0]], {opacity: 1})
    }

    return (
        <div className="w-full flex flex-col md:flex-row h-screen">
            {/*<div className="hidden lg:block absolute top-0 left-1/2 transform -translate-x-1/2 bg-white z-20 px-10 py-3 rounded-b-lg shadow">*/}
            {/*    <img className="w-32" src={logo} alt=""/>*/}
            {/*</div>*/}
            <div className="w-full relative h-full flex-shrink" ref={divisionOne} onMouseEnter={() => mouseOver(divisionOne)} onMouseLeave={mouseOut}>
                <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover flex flex-col justify-center items-center z-10" style={{backgroundImage: `url(${builder_bg})`}}>
                    <h2 className="relative z-10 text-4xl md:text-6xl font-bold uppercase font-primary text-white filter drop-shadow-xl tracking-wider mb-4">Builder</h2>
                    <p className="relative z-10 text-base md:text-lg tracking-wider md:opacity-0 md:top-10 text-center relative text-white filter drop-shadow-lg w-3/4 mx-auto">
                        Specialization in a wide range of projects, from modern residential communities to commercial developments and infrastructure projects.
                        We are characterized by their innovative designs, superior construction quality, and attention to detail.
                    </p>
                    <div className="absolute inset-0 flex items-center flex-col justify-center bg-dark bg-opacity-30 z-0"/>
                </div>
                <div className="absolute w-2/3 h-3 md:w-4 md:h-2/3 bg-primary border-2 border-white left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2 md:left-auto md:right-0 transform rounded-xl md:translate-x-1/2 z-10 md:top-1/2 md:-translate-y-1/2" ref={middleDivider}/>
            </div>
            <div className="w-full relative h-full flex-shrink" ref={divisionTwo} onMouseEnter={() => mouseOver(divisionTwo)} onMouseLeave={mouseOut}>
                <div className="absolute top-0 left-0 w-full h-full bg-center bg-no-repeat bg-cover flex flex-col justify-center items-center" style={{backgroundImage: `url(${contractor_bg})`}}>
                    <h2 className="relative z-10 text-4xl md:text-6xl font-bold uppercase font-primary text-white filter drop-shadow-2xl tracking-wider mb-4">Contractor</h2>
                    <p className="relative z-10 text-base md:text-lg tracking-wider md:opacity-0 md:top-10 relative text-center text-white filter drop-shadow-lg w-3/4 mx-auto">
                        EPPL has established a reputation for excellence in the industry, and is respected for its ability to handle complex projects and deliver them to the highest standards.
                        From construction services, including civil works, infrastructure, and building construction, we have a team of highly skilled and experienced professionals who specialize in a wide range of construction services, including civil works, infrastructure, and building construction.
                    </p>
                    <div className="absolute inset-0 flex items-center flex-col justify-center bg-dark bg-opacity-30 z-0"/>
                </div>
            </div>
        </div>
    );
}

export default MainDivision;