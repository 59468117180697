import React, { useEffect } from "react";
import banks from '../assets/images/banking_partners.jpg'
import Slider from "../components/Slider.js";


function AboutOverview() {

    useEffect(() => {
    });

    return (
        <div className="w-full py-10 px-8 md:px-28 xl:px-40 md:mt-12">
            <h3 className="text-3xl md:text-5xl text-primary font-primary filter drop-shadow-xl font-bold text-center mb-3 md:mb-8">About
                Us</h3>
            <div className="flex flex-col md:flex-row py-3 md:py-6">
                {/*<div className="w-full md:w-1/3 text-center">*/}
                {/*    <img src="https://source.unsplash.com/500x500/?company" alt=""/>*/}
                {/*</div>*/}
                <p className="text-sm leading-relaxed text-justify md:text-lg w-full md:leading-loose lg:columns-2 lg:gap-8 xl:gap-12">Starting in 1970s' as MS M C Jain we have taken many
                    government civil contracts and delivered it will full client satisfaction. We have been working for
                    Government Organizations such as BSNL, CCL and CPWD.
                    We entered the Development Business under the brand name of Shreya Constructions in 2005.
                    Successfully completed various Job Works for Companies, Hospitals, Schools, Industries,
                    private-building and homes.
                    Since then we are committed in providing modern residential and commercial opportunities to those
                    who are looking for the best use of their hard earned money. To build world class real estate
                    concepts with the highest standards of professionalism, ethics, quality and customer service.
                    We are the patent members of BIA (Builders' Association of India) and Chamber of Commerce for more
                    than 15 years.
                    Enhance projects has specialized experience in the
                    Construction Industry for more than 30 years, we at Enhance Projects, have carved a niche for
                    ourselves in the industry as one of the leading Civil Contractors and Real Estate Developer of the
                    region.
                    Enhance Projects have a commitment to high construction quality. We have an ideological foundation
                    in respect of commitment to the time limit of project completion . High quality construction have
                    been the main reason for our rapid growth over the years. EPPL pursuit for customer satisfaction has
                    won us several prestigious awards and all round acclaim in the past</p>
            </div>
            <div className="w-full lg:px-10 xl:px-16 py-6">
                <Slider />
            </div>
            <h3 className="text-xl md:text-3xl text-primary font-primary filter drop-shadow-xl font-bold text-center md:mt-6 mb-4">Our
                Banking Partners</h3>
            <p className="text-sm leading-relaxed text-justify md:text-lg w-full md:px-8 md:leading-loose">
                We have tied up with SBI, AXIS, UNION BANK, HDFC .In case our buyers need Housing Loan facilities. Home
                loan shall be given on BANK'S terms. In the event that a buyer takes a housing loan from any other
                bank/financial institution, he has to ensure timely payment according to the sale agreement. There will
                be penalties in case of late payment according to the sale agreement.
            </p>
            <div className="w-full md:px-10 mt-4">
                <img className="w-full" src={banks} alt="" />
            </div>
        </div>
    );
}

export default AboutOverview;