import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const container = {
    show: {
        transition: {
            staggerChildren: 0.1,
        }

    }
}

const item = {
    hidden: {
        opacity: 0,
        y: 50
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeInOut",
            duration: .5,
        },

    },

};


class ProjectsGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            activeProject: props.data[0]
        };
    }

    openModal = (project_data) => {
        this.setState((prev) => ({
            ...prev,
            modalIsOpen: true,
            activeProject: project_data
        }))
    }

    closeModal = () => {
        this.setState((prev) => ({
            ...prev,
            modalIsOpen: false
        }))
    }


    render() {
        const projects = this.props.data
        return (
            <div className=" overflow-hidden relative h-full">
                <div className="absolute w-full h-full left-0 top-0 z-20 pointer-events-none"></div>
                <motion.div variants={container} initial='hidden' animate='show'
                            className="w-full grid relative grid-cols-2 lg:grid-cols-3 py-4 justify-evenly z-10 justify-items-center gap-2 lg:px-12 lg:gap-6 xl:gap-8">
                    {
                        projects.map((project, i) => <motion.div variants={item} key={i}
                                                                 className="cursor-pointer overflow-hidden group relative h-full w-full"
                                                                 onClick={() => this.openModal(project)}>

                                <div className="w-full p-0 lg:p-1.5 h-full relative overflow-hidden">
                                    <div className="absolute w-full bg-secondary h-full left-0 bottom-0 z-[-1] -skew-y-12 translate-y-1/3"/>
                                    <img src={project.image} alt=" " className="border w-full h-full object-cover"/>
                                    <div className="absolute flex flex-col justify-center w-full h-full bg-white text-white opacity-0 group-hover:opacity-100 pointer-events-none bg-opacity-0 items-center group-hover:top-0 group-hover:left-0 group-hover:bg-opacity-50 transition-all z-10">
                                        <h4 className="mt-5 text-accent font-primary lg:text-3xl font-semibold">{project.name}</h4>
                                        <hr className="w-2/6 h-1 text-center"/>
                                        <p className="px-4 text-center font-bold text-dark">{project.location}</p>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    }
                </motion.div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="EPPL Project"
                    portalClassName={"ReactModalPortal fixed z-[9999]"}
                    bodyOpenClassName={"ReactModal__Body--open overflow-hidden"}
                    className="absolute w-11/12 lg:w-1/2 bg-white outline-none rounded border py-8 px-6 lg:py-20">
                    <div className="w-full flex flex-col items-center justify-center">
                        <span className="material-icons-round absolute top-1 right-1 lg:top-4 lg:right-4 z-50 text-3xl lg:text-5xl cursor-pointer transition-all hover:rotate-180" onClick={this.closeModal}>close</span>
                        <img src={this.state.activeProject.image} alt=" "
                             className="border w-full lg:w-1/3 h-full object-cover"/>
                        <h3 className="text-xl font-bold uppercase text-primary">{this.state.activeProject.name} ({this.state.activeProject.year})</h3>
                        <p className="text-sm">A <strong>{this.state.activeProject.type} Project</strong> at <strong>{this.state.activeProject.location}</strong>
                        </p>
                        {
                            this.state.activeProject.brochure !== null &&
                            <div className="w-full flex items-center text-center mt-2 lg:mt-6">
                                <a href={this.state.activeProject.brochure}
                                   className="block bg-secondary px-4 py-1 text text-lg text-white mx-auto hover:bg-transparent border border-secondary hover:text-primary transition-all shadow hover:shadow-md"
                                   download={this.state.activeProject.name}>
                                    Download Brochure
                                </a>
                            </div>
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}

export default ProjectsGallery;