import React, {useEffect, useState} from "react";
import ProjectsGallery from "../components/ProjectsGallery";
import Map from "../components/Map";
import Footer from "../components/Footer";
import {motion} from 'framer-motion';
import {Helmet} from "react-helmet";
const projects_img = require("../assets/images/projects-min.jpg")

const projects_list = require("../assets/projects.json").projects

const container = {
    show: {
        transition: {
            staggerChildren: 0.3,
        }

    }
}

const item = {
    hidden: {
        opacity: 0,
        y: 50
    },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: "easeInOut",
            duration: 0.5,
        },

    },

};

const Project = () => {


    const [items, setItems] = useState(projects_list);

    const [active, setActive] = useState("All");


    const filterItem = (categItem) => {
        setActive(categItem)
        const updatedItems = projects_list.filter((curEle) => {
            if (categItem === "All") {
                return projects_list
            }
            return curEle.type === categItem
        });
        setItems(updatedItems);

    }
    useEffect(() => {
        // filterItem('RESIDENTIAL')
    }, []);

    return (

        <div className="w-full">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Our Projects | Enhance Projects Private Limited</title>
                <meta name="description"
                      content="Explore the diverse portfolio of projects completed by Enhance Projects Private Limited.
                      From government contracts to residential and commercial developments, our team has a proven track
                      record of delivering high-quality, innovative solutions. Browse our portfolio and see why
                      we are the builder of choice for so many."/>
            </Helmet>
            <div className="w-full h-[50vh] lg:h-[80vh] bg-cover bg-no-repeat bg-center"
                 style={{backgroundImage: `url(${projects_img})`}}>
                <div className="w-full relative flex bg-dark bg-opacity-50 items-center justify-center h-full">
                    <h2 className="text-white text-center text-4xl lg:text-6xl font-primary font-bold tracking-wide">EPPL
                        Projects</h2>
                </div>
            </div>
            <div className=" px-5 py-5 lg:py-10 md:px-28 lg:px-40 ">
                <div
                    className="w-full border-b border-accent pb-10">
                    <p className="text-xs leading-relaxed lg:text-sm lg:text-base w-full text-justify">We are proud to
                        showcase our diverse portfolio of
                        completed and ongoing projects that demonstrate our commitment to excellence and innovation in
                        the
                        construction and real estate development industry. With over 30 years of experience, we have
                        established ourselves as one of the leading civil contractors and real estate developers in the
                        region. Our projects range from modern residential communities to commercial developments and
                        infrastructure projects. Each project is executed with the highest standards of professionalism,
                        ethics, quality and customer service. We take great pride in our ability to deliver projects on
                        time
                        and within budget, while exceeding our clients' expectations. Browse through our portfolio and
                        discover the quality and excellence that sets us apart.
                    </p>
                </div>
                <div className="w-full lg:py-12">
                    {/*<h2 className="text-primary text-center text-2xl lg:text-4xl font-primary font-bold tracking-wide mb-6">Our Projects</h2>*/}
                    <div className="relative lg:flex">
                        <div
                            className="font-primary text-primary text-xs flex flex-col justify-items-start items-center w-full lg:w-1/4 xl:w-1/5">
                            <motion.div variants={container} initial='hidden' animate='show'
                                        className="md:text-xl flex flex-wrap text-center lg:flex-col text-primary lg:text-lg w-full mt-4 mb-4 text-dark text-opacity-60 justify-evenly border border-transparent lg:border-light lg:py-4 lg:px-3">
                                <h2 className="hidden lg:block lg:block text-lg lg:text-primary_light text-primary lg:text-xl xl:text-2xl font-primary w-full text-left font-medium">Project
                                    Categories</h2>
                                {
                                    <motion.div variants={item}
                                                className="lg:py-2.5 w-full py-1 flex flex-wrap lg:flex-col justify-evenly lg:mt-4 lg:pl-2">
                                        <div
                                            className="flex flex-col lg:flex-row cursor-pointer relative py-2 border-b border-dark border-opacity-20 border-t even:border-light last:border-b-0 first:border-t-0"
                                            onClick={() => filterItem("All")}>
                                            <p className={"project_filter text-sm lg:text-lg border border-dark lg:border-0 px-2.5 py-1.5 lg:py-1 transition-all " + (active === "All" ? " text-dark bg-transparent active" : " text-light bg-dark lg:bg-transparent lg:text-dark")}
                                            >
                                                All
                                            </p>
                                        </div>
                                        <div
                                            className="flex flex-col lg:flex-row relative cursor-pointer py-2 border-b border-dark border-opacity-20 border-t even:border-light last:border-b-0 first:border-t-0"
                                            onClick={() => filterItem('Residential')}>
                                            <p className={"project_filter text-sm lg:text-lg border border-dark lg:border-0 px-2.5 py-1.5 lg:py-1 transition-all " + (active === "Residential" ? " text-dark bg-transparent active" : " text-light bg-dark lg:bg-transparent lg:text-dark")}
                                            >Residential
                                            </p>
                                        </div>
                                        <div
                                            className="flex flex-col lg:flex-row relative cursor-pointer py-2 border-b border-dark border-opacity-20 border-t even:border-light last:border-b-0 first:border-t-0"
                                            onClick={() => filterItem('Commercial')}>
                                            <p className={"project_filter text-sm lg:text-lg border border-dark lg:border-0 px-2.5 py-1.5 lg:py-1 transition-all " + (active === "Commercial" ? " text-dark bg-transparent active" : " text-light bg-dark lg:bg-transparent lg:text-dark")}
                                            >Commercial
                                            </p>
                                        </div>
                                        <div
                                            className="flex flex-col lg:flex-row relative py-2 border-b cursor-pointer border-dark border-opacity-20 border-t even:border-light last:border-b-0 first:border-t-0"
                                            onClick={() => filterItem('Residential cum Commercial')}>
                                            <p className={"project_filter text-sm lg:text-lg border border-dark lg:border-0 px-2.5 py-1.5 lg:py-1 transition-all " + (active === "Residential cum Commercial" ? " text-dark bg-transparent active" : " text-light bg-dark lg:bg-transparent lg:text-dark")}
                                            >Residential cum Commercial
                                            </p>
                                        </div>
                                    </motion.div>
                                }
                            </motion.div>
                        </div>
                        <div className="w-full flex-1">
                            <h2 className="hidden lg:block text-center text-lg lg:text-primary_light text-primary lg:text-2xl xl:text-3xl font-primary w-full text-left font-medium">{active} Projects</h2>
                            <ProjectsGallery data={items}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-96 w-full">
                <Map/>
            </div>
            <Footer/>
        </div>
    )
}

export default Project;
