import React from "react"
import logo from '../assets/images/logo.png';
import fb from '../assets/facebook.svg';
import twitter from '../assets/twitter.svg';
import insta from '../assets/insta.svg';
import whatsapp from '../assets/whatsapp.svg'
import {NavLink} from "react-router-dom";



function Footer() {
    return (
        <div className="h-auto w-full mt-10 xl:mt-4 xl:mb-4 bg-white">
            <div className="flex w-full flex-col xl:flex-row  justify-center text-center ">
                <div className="justify-center flex-1 items-center flex">
                    <img className="w-2/3 xl:w-9/12 " src={logo} alt="" />
                </div>
                <div className="flex-1 mt-10 xl:text-left ">
                    <h2 className="text-2xl xl:pl-9 xl:text-xl font-serif font-bold inline border-b-2 border-secondary  text-secondary">SITEMAP</h2>
                    <div className="flex flex-col p-4 text-xs lg:text-sm text-left">
                        <NavLink to={"/"} className="hover:text-primary transition-all mb-0.5 font-semibold">
                            Home
                        </NavLink>
                        <NavLink to={"/about"} className="hover:text-primary transition-all mb-0.5 font-semibold">
                            About Us
                        </NavLink>
                        <NavLink to={"/projects"} className="hover:text-primary transition-all mb-0.5 font-semibold">
                            Projects
                        </NavLink>
                        <NavLink to={"/assistance"} className="hover:text-primary transition-all mb-0.5 font-semibold">
                            Assistance
                        </NavLink>
                        <NavLink to={"/services"} className="hover:text-primary transition-all mb-0.5 font-semibold">
                            Services
                        </NavLink>
                    </div>
                </div >

                {/*<div className="flex-1 mt-10 xl:text-left">*/}
                {/*    <h2 className="text-2xl xl:text-xl font-serif font-bold inline border-b-2 border-secondary  text-secondary">SUBSCRIBE</h2>*/}
                {/*    <div className="px-12 xl:px-0 mt-4 xl:text-xs">*/}
                {/*        <p>Subscribe to get latest updates and offers from us.</p>*/}
                {/*        <div className=" flex relative sm:justify-center">*/}
                {/*            <input className="shadow border-2 border-secondary pr-12 xl:pr-14 w-full xl:w-full sm:w-4/6 py-2 px-3 xl:px-0 leading-tight focus:outline-none focus:shadow-outline" id="email" type="email" placeholder="Email Address" />*/}
                {/*            <button className="bg-secondary absolute right-0 sm:right-20 h-full w-12 xl:right-0">*/}
                {/*                <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full*/}
                {/*                 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">*/}
                {/*                    <path strokeLinecap="" strokeLinejoin="" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />*/}
                {/*                </svg>*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="flex-1 mt-10 xl:text-left">
                    <h2 className="text-2xl xl:text-xl xl:pl-6 font-serif font-bold inline border-b-2 border-secondary  text-secondary">CONTACT</h2>
                    <div className="mt-4 text-xs lg:text-sm xl:pl-8 xl:text-left font-semibold">
                        <p>3N, 3rd Floor, Shree Gopal Complex,<br /> Kutchery Road, Ranchi-834001.<br />+91-651-221-1083<br />info@enhanceprojects.com</p>
                    </div>
                </div>
                <div className="flex-1 mt-10 mb-4">
                    <h2 className="text-2xl xl:text-xl font-serif font-bold inline border-b-2 border-secondary text-secondary">STAY CONNECTED </h2>
                    <div className="flex flex-row justify-center mt-4 ">
                        <a href="#">
                            <img className=" w-8 lg:w-10 xl:w-12 mx-2 drop-shadow-lg opacity-80 hover:opacity-100 transition-all" src={fb} alt="" /></a>
                        <a href="">
                            <img className=" w-8 lg:w-10 xl:w-12 mx-2 drop-shadow-lg opacity-80 hover:opacity-100 transition-all" src={insta} alt="" /></a>
                        <a href="">
                            <img className=" w-8 lg:w-10 xl:w-12 mx-2 drop-shadow-lg opacity-80 hover:opacity-100 transition-all" src={twitter} alt="" /></a>
                        <a href="">
                            <img className=" w-8 lg:w-10 xl:w-12 mx-2 drop-shadow-lg opacity-80 hover:opacity-100 transition-all" src={whatsapp} alt="" /></a>
                    </div>
                </div>

            </div>
        </div>
    );;
}
export default Footer;
