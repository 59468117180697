import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";
import Map from "../components/Map";
import Footer from "../components/Footer";
import AboutOverview from '../components/AboutOverview';

const Builder = () => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };
    return (
        <div className="h-full">
            <Slider {...settings} className=" w-full">
                <div>
                    <img className=" shadow-lg" src="http://placehold.it/1280x480" alt="" />
                </div>
                <div>
                    <img className=" shadow-lg" src="http://placehold.it/1280x480" alt="" />
                </div>
                <div>
                    <img className=" shadow-lg" src="http://placehold.it/1280x480" alt="" />
                </div>
                <div>
                    <img className=" shadow-lg" src="http://placehold.it/1280x480" alt="" />
                </div>
                <div>
                    <img className=" shadow-lg" src="http://placehold.it/1280x480" alt="" />
                </div>
                <div>
                    <img className=" shadow-lg" src="http://placehold.it/1280x480" alt="" />
                </div>
            </Slider>
            <h3 className="text-3xl pt-10 md:text-5xl text-primary font-primary filter drop-shadow-xl font-bold text-center mb-3 md:mb-8">We As a Builder</h3>
            <div className="flex flex-col md:flex-row py-3 md:py-6">
                {/*<div className="w-full md:w-1/3 text-center">*/}
                {/*    <img src="https://source.unsplash.com/500x500/?company" alt=""/>*/}
                {/*</div>*/}
                <p className="text-sm leading-relaxed text-justify md:text-lg w-full md:px-8 md:leading-loose">Starting in 1970s' as MS M C Jain we have taken many
                    government civil contracts and delivered it will full client satisfaction. We have been working for
                    Government Organizations such as BSNL, CCL and CPWD.
                    We entered the Development Business under the brand name of Shreya Constructions in 2005.
                    Successfully completed various Job Works for Companies, Hospitals, Schools, Industries,
                    private-building and homes.
                    Since then we are committed in providing modern residential and commercial opportunities to those
                    who are looking for the best use of their hard earned money. To build world class real estate
                    concepts with the highest standards of professionalism, ethics, quality and customer service.
                    We are the patent members of BIA (Builders' Association of India) and Chamber of Commerce for more
                    than 15 years.</p>
                <p className="text-sm leading-relaxed text-justify md:text-lg w-full md:px-8 md:leading-loose">Starting in 1970s' as MS M C Jain we have taken many government civil
                    contracts and delivered it will full client satisfaction. We have been working
                    for Government Organizations such as BSNL, CCL and CPWD. We entered
                    the Development Business under the brand name of Shreya Constructions
                    in 2005. Successfully completed various Job Works for Companies,
                    Hospitals, Schools, Industries, private-building and homes. Since then we
                    are committed in providing modern residential and commercial
                    opportunities to those who are looking for the best use of their hard
                    earned money. To build world class real estate concepts with the highest
                    standards of professionalism, ethics, quality and customer service. We are
                    the patent members of BIA (Builders' Association of India) and Chamber of
                    Commerce for more than 15 years.
                </p>
            </div>
            <AboutOverview />
            <div className="h-96 w-full">
                <Map />
            </div>
            <Footer />
        </div>
    );
}

export default Builder
