import React from 'react'

const Contacts = () => {
    return (
        <div>
            Contats
        </div>
    )
}

export default Contacts
