import React from "react";
import MainDivision from "./components/MainDivision";
import AboutOverview from "./components/AboutOverview";
import Map from "./components/Map";
import Footer from "./components/Footer";
import {Helmet} from "react-helmet";
import ContactForm from "./components/ContactForm";


function Home() {
    return (
        <div className="relative w-full h-full">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Enhance Projects Private Limited | Building Futures, One Project at a Time</title>
                <meta name="description" content="Enhance Projects Private Limited is a leading construction and real estate development
                      company with over 30 years of experience. We specialize in delivering high-quality projects with
                      a commitment to time, ethics, and customer satisfaction. Explore our portfolio and discover
                      how we can help build your future."/>
            </Helmet>
            <MainDivision/>
            <AboutOverview/>
            <div className="w-full px-5 md:px-28 lg:px-40 lg:py-12">
                <ContactForm/>
            </div>
            <div className="h-96 w-full">
                <Map/>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;
