import React, {useState} from 'react'
import ContactForm from "../components/ContactForm";
import Map from "../components/Map";
import Footer from "../components/Footer";

const assistance_img = require("../assets/images/assistance-min.jpg")
const calculator_img = require("../assets/images/calculator.png")

const Assistance = () => {
    const [amount, setAmount] = useState("");
    const [rate, setRate] = useState("");
    const [tenure, setTenure] = useState("");
    const [tenureType, setTenureType] = useState("y");
    const [error, setError] = useState(false);
    const [values, setValues] = useState({"emi": 0, "interest_amount": 0, "payable": 0})

    function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    const calculate_emi = (e) => {
        e.preventDefault();
        setError(false)
        if (isNumeric(amount) || isNumeric(rate) || isNumeric(tenure)) {
            const principle = parseFloat(amount).toFixed(2)
            const interest_rate = parseFloat(rate).toFixed(2)
            const monthly_interest_rate = (interest_rate / 1200)
            const months = tenureType === "m" ? parseInt(tenure) : parseInt(tenure) * 12
            const emi = principle * ((monthly_interest_rate * Math.pow(monthly_interest_rate + 1, months)) / (Math.pow(monthly_interest_rate + 1, months) - 1))
            const payable = (emi.toFixed(2) * months).toFixed(2)
            setValues({"emi": emi.toFixed(2), "interest_amount": (payable - amount).toFixed(2), "payable": payable})
        }
        return setError(true);
    }

    return (
        <div className="w-full relative">
            <div className="w-full h-[50vh] lg:h-[80vh] bg-cover bg-no-repeat bg-center"
                 style={{backgroundImage: `url(${assistance_img})`}}>
                <div className="w-full relative flex flex-col bg-dark bg-opacity-50 items-center justify-center h-full">
                    <h2 className="text-white text-center text-4xl lg:text-6xl font-primary font-bold tracking-wide">Assistance</h2>
                    <p className="text-center text-lg lg:text-xl xl:text-3xl font-text text-accent mt-10 ">
                        "Wherever you go, no matter what the weather, always bring your own sunshine"
                    </p>
                </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row px-5 py-5 lg:py-10 md:px-28 lg:px-40 lg:space-x-12">
                <div className="w-full lg:w-1/2">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-primary text-primary text-center font-semibold">
                        EMI Calculator
                    </h2>
                    <div className="w-full flex mt-3 lg:mt-6">
                        <div className="w-full flex flex-col px-4">
                            <div className="flex w-full flex-col lg:flex-row lg:items-center my-3">
                                <span
                                    className="text-sm lg:text-base text-dark opacity-80 whitespace-nowrap">Loan Amount:</span>
                                <div className="flex flex-1 px-2 w-full mx-auto mt-1">
                                    <input type="number"
                                           className="flex-1 border border-secondary px-2 text-primary focus:outline-0"
                                           placeholder="Enter Loan Amount" value={amount}
                                           onChange={(e) => setAmount(e.target.value)}/>
                                    <span
                                        className="material-icons-round text-xl p-2 px-3 bg-dark text-white bg-opacity-80">currency_rupee</span>
                                </div>
                            </div>
                            <div className="flex w-full flex-col lg:flex-row lg:items-center my-3">
                                <span
                                    className="text-sm lg:text-base text-dark opacity-80 whitespace-nowrap">Interest Rate:</span>
                                <div className="flex flex-1 px-2 w-full mx-auto mt-1">
                                    <input type="number"
                                           className="flex-1 border border-secondary px-2 text-primary focus:outline-0"
                                           placeholder="Enter Interest Rate" value={rate}
                                           onChange={(e) => setRate(e.target.value)}/>
                                    <span
                                        className="material-icons-round text-xl p-2 px-3 bg-dark text-white bg-opacity-80">percent</span>
                                </div>
                            </div>
                            <div className="flex w-full flex-col lg:flex-row lg:items-center my-3">
                                <span
                                    className="text-sm lg:text-base  text-dark opacity-80 whitespace-nowrap">Loan Tenure:</span>
                                <div className="flex flex-1 px-2 w-full mx-auto mt-1">
                                    <input type="number"
                                           className="flex-1 border border-secondary px-2 text-primary focus:outline-0"
                                           placeholder="Enter Loan Tenure" value={tenure}
                                           onChange={(e) => setTenure(e.target.value)}/>
                                    <div className="flex">
                                        <span
                                            className={"text-base cursor-pointer p-2 px-3 text-white bg-opacity-80 transition-colors " + (tenureType === "y" ? " bg-secondary" : " bg-dark")}
                                            onClick={() => setTenureType("y")}>Yr.</span>
                                        <span
                                            className={"text-base cursor-pointer p-2 px-3 text-white bg-opacity-80 transition-colors " + (tenureType === "m" ? " bg-secondary" : " bg-dark")}
                                            onClick={() => setTenureType("m")}>Mo.</span>
                                    </div>
                                </div>
                            </div>
                            {
                                error && <p className="text-red-500 text-xs lg:text-sm">Please ensure all values are numeric.</p>
                            }
                            <div className="flex w-full flex-col lg:flex-row lg:items-center my-3">
                                <button
                                    disabled={amount === "" || rate === "" || tenure === ""}
                                    onClick={calculate_emi}
                                    className="block disabled:cursor-not-allowed disabled:opacity-60 bg-secondary text-primary px-4 py-1 text text-lg uppercase font-semibold mx-auto hover:bg-transparent border border-secondary hover:text-primary transition-all shadow hover:shadow-md">
                                    Calculate
                                </button>
                            </div>

                            <div className="flex w-full flex-col  lg:items-center my-3 border border-secondary">
                                <div
                                    className="w-full border-b border-dashed border-secondary border-opacity-50 flex flex-col items-center justify-center py-2">
                                    <p className="text-dark text-sm lg:text-base xl:text-lg">Loan EMI</p>
                                    <p className="text-secondary text-sm lg:text-base xl:text-lg">&#8377;{values.emi}</p>
                                </div>
                                <div
                                    className="w-full border-b border-dashed border-secondary border-opacity-50 flex flex-col items-center justify-center py-2">
                                    <p className="text-dark text-sm lg:text-base xl:text-lg">Total Interest Payable</p>
                                    <p className="text-secondary text-sm lg:text-base xl:text-lg">&#8377;{values.interest_amount}</p>
                                </div>
                                <div
                                    className="w-full border-b border-dashed border-secondary border-opacity-50 flex flex-col items-center justify-center py-2">
                                    <p className="text-dark text-sm lg:text-base xl:text-lg">Total Payable (Loan amount
                                        + Interest)</p>
                                    <p className="text-secondary text-sm lg:text-base xl:text-lg">&#8377;{values.payable}</p>
                                </div>
                            </div>
                        </div>
                        <div className="hidden lg:block">
                            <img src={calculator_img} alt="" className="drop-shadow-md opacity-50"/>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/2">
                    <h2 className="text-lg lg:text-xl xl:text-2xl font-primary text-primary text-center font-semibold">
                        Loan Assistance
                    </h2>
                    <div className="w-full flex mt-3 lg:mt-6 flex-col">
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-loose">
                            Enhance Projects understand that your home is important to you. It is a place in
                            the world that is yours, designed and developed by your tastes and as well as
                            needs. Home is that place where you celebrate the happiness with your family.
                            There is no place like 'Home' and with us you can get all these happiness and
                            create memories in your own home.
                        </p>
                        <ContactForm/>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col px-5 md:px-28 lg:px-40 py-4 lg:py-12">
                <h2 className="text-xl lg:text-2xl xl:text-3xl font-primary text-primary text-center font-semibold tracking-wide">Some Helpful Tips</h2>
                <div className="w-full my-2 lg:my-4">
                    <h4 className="text-base lg:text-lg font-primary text-primary text-left font-semibold mb-2">Ask Questions:</h4>
                    <div className="px-2 lg:px-5">
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            Before you decide to invest in a property, it is advised to study the pros & cons, know the legalities, documentation and other details of the property.
                        </p>
                    </div>
                </div>
                <div className="w-full my-2 lg:my-4">
                    <h4 className="text-base lg:text-lg font-primary text-primary text-left font-semibold mb-2">Scrutinize documents:</h4>
                    <div className="px-2 lg:px-5">
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            Titles of the property. <br/>
                            Sanction plans from the concerned authorities like Ranchi Regional Development Authority (RRDA), Ranchi Municipal Corporation (MC).<br/>
                            Nil loan amount for a minimum of 30 years, to ensure that the property is free from all loan.<br/>
                        </p>
                    </div>
                </div>
                <div className="w-full my-2 lg:my-4">
                    <h4 className="text-base lg:text-lg font-primary text-primary text-left font-semibold mb-2">Understand the additional charges involved:</h4>
                    <div className="px-2 lg:px-5">
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            Government levies like Registration and Stamp Duty.<br/>
                            service Tax.<br/>
                            Car Parking, depending a slot is opted for it or not.<br/>
                            Legal Charges.<br/>
                            Society Deposit.<br/>
                            Maintenance Charges.<br/>
                            Generator & Electricity Charges.<br/>
                        </p>
                    </div>
                </div>
                <div className="w-full my-2 lg:my-4">
                    <h4 className="text-base lg:text-lg font-primary text-primary text-left font-semibold mb-2">Approval from financial institutions:</h4>
                    <div className="px-2 lg:px-5">
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            Only properties with valid & clear legal titles are approved by leading financial institutions, thereby increasing the reliability of the develope decide to invest in has green lights from leading financial institutions.
                        </p>
                    </div>
                </div>
                <div className="w-full my-2 lg:my-4">
                    <h4 className="text-base lg:text-lg font-primary text-primary text-left font-semibold mb-2">Understanding Real Estate Jargon:</h4>
                    <div className="px-2 lg:px-5">
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            <strong>SUPER BUILT UP AREA:</strong>
                            This is the total built-up area of a residential apartment, including the common areas such as corridors, staircases, elevators, and other shared spaces.
                            It is typically used as a measure of the size of an apartment for the purpose of determining the price or rent. It generally larger than the usable area or carpet area of an apartment, as it includes the common areas as well.
                        </p>
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            <strong>BUILT UP AREA:</strong>
                            It is the total area of an apartment, including the covered area and the proportionate share of the common areas such as corridors, staircases, elevators, etc. It is the area that is enclosed by the walls of the apartment.
                        </p>
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            <strong>CARPET AREA:</strong>
                            Carpet area, on the other hand, is the area of an apartment that can be covered by a carpet, and it excludes the thickness of the walls.
                            It is the actual area where a person can lay a carpet. It is generally smaller than the built-up area because it does not include the common areas,
                            and it also excludes the thickness of the walls.
                        </p>
                        <p className="text-sm text-justify lg:text-base w-full text-primary leading-loose lg:leading-relaxed">
                            In summary, Super built-up area includes the carpet area and common area of an apartment, built-up area includes only the covered area of an
                            apartment while the carpet area only includes the area that can be covered by a carpet and excludes the thickness of the walls and common area.
                        </p>
                    </div>
                </div>
            </div>
            <div className="h-96 w-full">
                <Map />
            </div>
            <Footer />
        </div>
    )
}

export default Assistance
