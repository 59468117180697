import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
const projects_list = require("../assets/projects.json")


function NextArrow(props) {
    const { onClick } = props;
    return (
        <span className="absolute p-2 text-3xl top-1/2 right-4 -translate-y-1/2 transform material-icons-round cursor-pointer bg-transparent text-white stroke-2 border-2 border-white" onClick={onClick}>
            arrow_forward_ios
        </span>
    );
}

function PrevArrow(props) {
    const { onClick } = props;
    return (
        <span className="absolute p-2 text-3xl z-30 material-icons-round transform bottom-1/2 left-4 translate-y-1/2 cursor-pointer bg-transparent text-white stroke-2 border-2 border-white" onClick={onClick}>
            arrow_back_ios
        </span>
    );
}


const SimpleSlider = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }


            }
        ]
    };
    return (
        <div>
            <h3 className="text-xl md:text-3xl text-primary font-primary filter drop-shadow-xl font-bold text-center md:mt-6 mb-4">Projects Timeline</h3>
            <Slider {...settings} className="relative z-10" >
                {
                    projects_list.projects.map((pro, i) => <div className="relative group filter grayscale hover:grayscale-0 transition-all h-96" key={i}>
                        {/*<img className="w-full object-cover" src={`/${pro.image}`} alt="" />*/}
                        <div className="absolute inset-0 bg-no-repeat bg-center bg-cover" style={{backgroundImage: `url(${pro.image})`}}/>
                        <div className="top-0 text-center py-8 flex flex-col justify-between h-full w-full text-white absolute transition group-hover:bg-opacity-0 bg-black bg-opacity-40">
                            <div>
                                <h2 className="text-2xl border-b-2 inline-block">{pro.year}</h2>
                            </div>
                            <div>
                                <h2 className="py-3 text-xl font-bold" >{pro.name}</h2>
                                <div className="text-sm px-2 py-5 mx-3 group-hover:bg-secondary border border-white group-hover:border-secondary font-semibold text-sm">A {pro.type} Project at {pro.location}</div>
                            </div>
                        </div>
                    </div>).reverse()
                }
            </Slider>
            <div className="w-full flex items-center text-center mt-2 lg:mt-6">
                <Link to={"/projects"} className="bg-secondary px-4 py-2 text text-lg text-white mx-auto hover:bg-transparent border border-secondary hover:text-primary transition-all shadow hover:shadow-md">
                    View all
                </Link>
            </div>
        </div>
    )
}

export default SimpleSlider
